//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import CalendarMixin from "@/mixins/Calendar";
import AppSelect from "@/components/AppSelect.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import FiltersSelect from "@/components/FiltersSelect.vue";
import ABCTable from "@/components/Connections/ABCTable";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import MyShopsMixin from "@/mixins/MyShops";
import HasTokens from "@/components/HasTokens";
export default {
  mixins: [CalendarMixin, MyShopsMixin],
  meta: {
    title: "ABC Анализ"
  },
  data() {
    const localStorageCalendar = JSON.parse(localStorage.getItem("calendar"));
    if (localStorageCalendar) {
      this.$store.dispatch("connections/updateCalendar", localStorageCalendar);
    }
    return {
      ABCTableKey: 0,
      calendarInternal: null,
      categories: [],
      brands: [],
      calendar: this.$store.state.connections.calendar,
      newCategories: null,
      newBrands: null,
      loadingBrandsAndCategories: true
    };
  },
  methods: {
    async loadBrandsAndCategories() {
      this.loadingBrandsAndCategories = true;
      if (this.marketplace.title === "OZON") {
        /* &:
        this.newCategories = await this.$store.dispatch(
            "connections/getOzABCCategories"
        );
        //this.newCategories = { data: [] };
         //this.newBrands = { data: [] };
        // brands = await this.$store.dispatch('connections/getOzABCBrands')
        */

        this.newCategories = null;
        this.newBrands = null;
      }
      if (this.marketplace.title === "Wildberries") {
        this.newCategories = await this.$store.dispatch("connections/getWbABCCategories");
        this.newBrands = await this.$store.dispatch("connections/getWbABCBrands");
      }
      //},
      //updateBrandsAndCategories() {
      if (this.marketplace.title === "OZON") {
        /* &:
        this.categories = this.newCategories?.data.map(
            (item, index) => {
                return {
                    id: index,
                    title: item.category_name,
                    type: "category",
                    selected: true,
                };
            }
        );
        //this.categories = this.newCategories?.data;
         //this.brands = this.newBrands.data;
        */

        this.categories = [];
        this.brands = [];
      }
      if (this.marketplace.title === "Wildberries") {
        var _this$newCategories, _this$newBrands;
        this.categories = (_this$newCategories = this.newCategories) === null || _this$newCategories === void 0 ? void 0 : _this$newCategories.data.map((item, index) => {
          return {
            id: index,
            title: item.category,
            type: "category",
            selected: true
          };
        });
        this.brands = (_this$newBrands = this.newBrands) === null || _this$newBrands === void 0 ? void 0 : _this$newBrands.data.map((item, index) => {
          return {
            id: index,
            title: item.brand,
            type: "brand",
            selected: true
          };
        });
        this.ABCTableKey++;
      }
      this.loadingBrandsAndCategories = false;
    },
    async marketplaceWatcher() {
      await this.loadBrandsAndCategories();
      //this.updateBrandsAndCategories();
    }
  },

  async created() {
    await this.loadBrandsAndCategories();
    //this.updateBrandsAndCategories();
  },

  computed: {
    fileName() {
      const split1 = this.reportSettings.date.split("-");
      const split2 = this.reportSettings.date2.split("-");
      const dates = [split1[1] + "-" + split1[0] + "-" + split1[2], split2[1] + "-" + split2[0] + "-" + split2[2]];
      return `${this.marketplace.title} ABC Анализ ${dates[0]} - ${dates[1]}`;
    },
    reportSettings() {
      if (!this.calendar) {
        return null;
      }
      const dates = this.calendar.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });
      return {
        date: dates[0],
        date2: dates[1],
        tokens: this.selectedTokens.map(item => item.id),
        categories: this.categories ? this.categories.filter(item => item.selected).map(item => item.title) : null,
        brands: this.brands ? this.brands.filter(item => item.selected).map(item => item.title) : null
      };
    }
  },
  watch: {
    calendar() {
      this.$store.dispatch("connections/updateCalendar", this.calendar);
      localStorage.setItem("calendar", JSON.stringify(this.calendar));
    }
  },
  components: {
    MainTitle,
    ReportPage,
    AppSelect,
    AppSelectMulti,
    FiltersSelect,
    ABCTable,
    HasTokens,
    DataLoading,
    DataTableActions
  }
};